//Font
$bold: 700;
$regular: 400;
//Texts
$big-size: 1.8rem;
$regular-size: 1.6rem;
$medium-size: 1.5rem;
$small-size: 1.4rem;
$xsmall-size: 1.3rem;
$xxsmall-size: 1.2rem;
$tiny: 1.1rem;

//Titles
$xbig-title: 3.6rem;
$big-title: 3rem;
$medium-title: 2.4rem;
$small-title: 2rem;
$xsmall-title: 1.6rem;
//Colors
$accent: #186abd;
$bg-grey: #efeff1;
$bg-even: #f8f9fa;
$blackio: #242c35;
$border: #d4d9dd;
$blue-cool: #dae3ee;
$blue-cotton: #8EA9CB;
$blue-night: #1A5295;
$blue-deep: #05264E;
$bubble: #e4eaf1;
$caribe: #56c4bf;
$cherry: #c8342c;
$dip: #f7e3c4;
$grafito: #4f5964;
$grafito2: #7c848c;
$grass: #6bad22;
$grass-lite: #d4e4c2;
$hitblue: #8ea7c5;
$hover-raw: #E8EEF5;
$info: #fdcd72;
$lagoon: #b4d490;
$river: #d6e5c5;
$moe: #cdcfd2;
$mamey: #e59d99;
$metal: #9ba0a7;
$mint: #eef9f8;
$mustard: #f5a623;
$snow: #ffffff;
$primary: $grafito;
$secondary: $caribe;

//LEVELS
$color-pre: #D41C5B;
$color-pre-lite: #F0B8CB;
$color-pri: #F36D42;
$color-pri-lite: #FCD3C6;
$color-sec: #009AC2;
$color-sec-lite: #B0DEEB;

//MISC
$large: 100%;
$medium: 49%;
$small: 32%;
$shadow-box: 0 0.3rem 1rem $moe;
$shadow-box-sider: 0 0.1rem 0.1rem $metal;

//RESPONSIVE
$device: (
  'tiny':   360px,
  'small':  500px,
  'medium': 1024px,
  'large':  1200px
) !default;
